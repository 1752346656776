@import "../../../../../assets/scss/avocadoVariables";

.readableValues {
  display: flex;
  flex-direction: column;
  padding: map-get($spacing, xs);
  border-radius: map-get($radius, md);
  background-color: var(--color-blocked-opacity);
  gap: map-get($spacing, xxs);

  &_item {
    .title {
      font-weight: map-get($fonts, bold);
    }
  }
}
