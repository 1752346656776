@import "./../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: map-get($radius, md);
}

::-webkit-scrollbar-thumb {
  background: var(--color-neutral-30);
  border-radius: map-get($radius, md);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.divider {
  border-color: var(--color-neutral-10) !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: map-get($spacing, sm) 0 map-get($spacing, xl) !important;
  &:hover {
    background: none !important;
  }
}

.content {
  display: flex !important;
  flex-direction: column !important;
  padding: 0 0 map-get($spacing, xl) !important;
  &:hover {
    background: none !important;
  }
  &:active {
    background: none !important;
  }
}

/* Hide hamburger button, because now handle inside <Layout> component */
.bm-burger-button {
  display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  height: 100vh !important;
  outline: none;
  padding: 50px 0 0 !important;
  position: fixed;
  width: 324px !important;
}

/* General sidebar styles */
.bm-menu {
  @include text(0.9rem, layout-grey-90, bold);
  background: var(--color-neutral-00);
  padding: map-get($spacing, lg) 0 0 0;
  height: 100%;
  z-index: 998 !important;
  box-shadow: var(--shadow-elevate);
  overflow-y: auto;
}

.bm-overlay {
  top: 0;
  height: 100vh;
  z-index: 997 !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  @include text(1.08rem, layout-grey-90);
  padding: 0 map-get($spacing, sm);
}

/* Individual item */

.sidebar-title {
  padding: 0 map-get($spacing, xs) !important;
  margin: 0 !important;
  cursor: default !important;
  color: var(--color-neutral-50) !important;
  &:hover {
    background-color: transparent !important;
  }
}

.bm-item {
  color: var(--color-neutral-70);
  padding: map-get($spacing, xs);
  font-size: map-get($sizeText, 16);
  transition: color 0.3s ease, background 0.3s ease;
  &:not(:first-child) {
    margin: map-get($spacing, sm) 0 0;
  }
  border-radius: map-get($radius, lg);
  &:hover {
    background: var(--color-neutral-30-20);
  }
  &:active {
    color: var(--color-gray-05);
    background: var(--color-primary-50);
  }
}
