@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.addLinkSection {
  padding: map-get($spacing, md);

  &_title {
    margin: 0 0 map-get($spacing, xl);
  }

  &_createLinkLoading {
    display: none;
  }

  &_description {
    margin: 0 0 map-get($spacing, sm);
  }

  &_options {
    display: flex;
    gap: map-get($spacing, sm);

    .radioButton {
      width: fit-content;
    }
  }
}

.cntAddLink {
  padding: map-get($spacing, md);
  border-radius: 0.5rem;
  border: 1px solid map-get($colors, layout-grey-30);
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, lg);

  &_title {
    font-size: map-get($sizeText, 16);
    line-height: map-get($sizeText, 18);
    font-weight: map-get($fonts, medium);
    color: map-get($colors, layout-grey-90);
  }

  .sectionsTitle {
    font-size: map-get($sizeText, 14);
    line-height: map-get($sizeText, 14);
    font-weight: map-get($fonts, medium);
    color: map-get($colors, layout-grey-90);
    margin: 0 0 map-get($spacing, sm);
  }

  .cntOption {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);
  }

  .cntCreateLink {
    &_title {
      font-size: map-get($sizeText, 14);
      line-height: map-get($sizeText, 14);
      font-weight: map-get($fonts, medium);
      color: map-get($colors, layout-grey-90);
      margin: 0 0 map-get($spacing, sm);
    }

    .listComponent {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: map-get($spacing, sm);

      &_item {
        .componentType {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr;
          gap: map-get($spacing, xxs);

          &_title {
            font-size: map-get($sizeText, 12);
            line-height: map-get($sizeText, 14);
            font-weight: map-get($fonts, medium);
            color: map-get($colors, darwined-blue-50);
          }

          &_options {
            display: grid;
            grid-template-columns: repeat(2, auto);
            justify-content: flex-start;
            gap: map-get($spacing, sm);
          }

          &_select {
            padding: 12px 0 0;
          }
        }
      }
    }

    .listValidation {
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, xxs);

      &_item {
        font-size: map-get($sizeText, 14);
        line-height: map-get($sizeText, 16);
        color: map-get($colors, layout-grey-90);

        &__error {
          color: map-get($colors, message-negative-50);
        }
      }
    }

    .validationItem {
      padding: map-get($spacing, xxs) 0 0;
    }
    .footer {
      &_btn {
        &:first-of-type {
          margin: 0;
        }
        margin: 0 0 0 12px;
      }
    }
  }
  .cntEmpty {
    width: 100%;
  }
}
