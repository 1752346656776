@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.restActions {
  display: grid;
  gap: map-get($spacing, md);
  grid-template-columns: 1fr 1fr;
  margin: map-get($spacing, md) 0 0;

  .btnMonitor {
    margin: 0 0 0 auto;
  }
}
