@import "../../../../../../assets/scss/avocadoVariables";

.recommendationsSectionRow {
  display: grid;
  grid-template-columns: 72px 1fr;

  &_title {
    padding: 0 map-get($spacing, xxs) 0 0;
    margin: map-get($spacing, xxs) 0 0;
  }

  &_items {
    display: flex;
    flex-wrap: wrap;
    gap: map-get($spacing, xs);

    .tagItem {
      min-width: unset;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: map-get($spacing, xs);
      }
    }

    .linkItem {
      display: flex;
      align-items: center;
    }
  }
}
