@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.context_content {
  padding: map-get($spacing, md);
  min-height: 200px;
  .context_select {
    @include flex(space-between);
    min-height: 200px;
    padding: map-get($spacing, md) 0 map-get($spacing, md) 0.625rem;
    .input_select {
      @include text(map-get($sizeText, 12), layout-grey-50);
      width: 100%;
      min-width: 180px;
      height: 100%;
      padding: 0.625rem 0;
      margin: 0 0 0 0.625rem;
      label {
        margin: 0 0.625rem 0 0;
      }
      &:first-child {
        margin: 0;
      }
    }
  }

  .context_form {
    margin: 0.625rem 0 0;
    display: flex;
    justify-content: flex-end;

    &.context_form__btnTranslation {
      justify-content: space-between;
      align-items: center;
    }
  }
}
