@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

.advanceSelector {
  display: flex;
  align-items: center;
  gap: map-get($spacing, sm);
  width: 100%;

  &_field {
    width: 200px;
  }

  &_select {
    width: 264px;
  }

  [class*="reactSelect__option--is-selected"] {
    [class*="selectors_cntOptions_title"] {
      color: var(--color-neutral-00);
    }
  }
}
