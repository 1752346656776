@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.contextScenario {
  flex: 1;
  max-width: 412px;
  border-left: map-get($borders, md) solid var(--color-neutral-30);
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: map-get($spacing, xl);
  padding: 0 map-get($spacing, sm);
  align-items: center;
  margin: 0 0 0 map-get($spacing, sm);

  &:hover {
    background-color: map-get($colors, layout-grey-5);
    color: map-get($colors, layout-grey-90);
  }

  &_title {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    .label {
      font-size: map-get($sizeText, 10);
      line-height: map-get($sizeText, 12);
      color: #8c93a6;
      font-weight: 500;
      margin: 0 0 map-get($spacing, xxs);
    }

    .info {
      font-size: map-get($sizeText, 12);
      line-height: map-get($sizeText, 16);
      color: #465669;
      font-weight: 500;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
