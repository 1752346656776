@import "../../../../assets/scss/avocadoVariables";
@import "../../../../assets/scss/mixin";

.creationForm {
  padding: map-get($spacing, xxl) map-get($spacing, xxxl);

  &_content {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);

    .select {
      width: 400px;

      .select_topLabel {
        color: var(--color-neutral-90);
      }

      .select_bottomLabel {
        color: var(--color-neutral-50);
      }
    }

    .textField {
      width: 250px;
    }
  }

  &_actionsButtons {
    display: flex;
    gap: map-get($spacing, sm);
    margin: map-get($spacing, xxl) 0 0;
  }
}

.main {
  @include text(0.7rem, layout-grey-90);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5rem;

  .selectorGroup {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .packageName {
    max-width: 418px;
    margin-top: 1.5rem;
  }

  .selector {
    margin: map-get($spacing, xl) 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;

    &_control {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;

      .label {
        @include text(0.75rem, layout-grey-90, bold);
      }

      .term {
        width: 100%;
        max-width: 300px;
      }
    }

    &_input {
      margin-right: 1rem;
      min-width: 30%;
      padding: 0;
      width: auto;

      &__population {
        width: 50%;
        max-width: 418px;

        &__label_top {
          color: var(--color-neutral-90);
        }
        &__label_bottom {
          color: var(--color-neutral-90);
        }
      }
      p {
        padding: 0;
      }
    }

    &_button {
      &:last-of-type {
        margin: 0 0 0 auto;
      }
    }
  }

  .packageLabel {
    display: flex;
    flex-direction: column;
    text-align: left;

    &_mainLabel {
      font-size: map-get($sizeText, 12);
      color: var(--color-neutral-70);
    }

    &_secondaryLabel {
      font-size: map-get($sizeText, 10);
      color: var(--color-neutral-50);
    }
  }

  [class*="reactSelect__option"]:hover {
    background-color: var(--color-neutral-30-20);
  }

  [class*="reactSelect__option--is-selected"],
  [class*="reactSelect__option"]:focus,
  [class*="reactSelect__option"]:active {
    background-color: var(--color-primary-50);

    p {
      color: var(--color-gray-00) !important;
    }
  }

  .buttons_section {
    width: 60%;
    border-top: 1px solid map-get($colors, layout-grey-20);
    padding-top: 1rem;
    margin-top: 1rem;
    display: flex;
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: map-get($spacing, sm);
    margin: map-get($spacing, xxl) 0 0;
  }

  .errorCard {
    margin: map-get($spacing, xl) auto 0 0;
    width: auto;
  }

  .packageLink {
    color: map-get($colors, darwined-blue-30);
  }
}
