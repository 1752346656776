@import "../../../../../assets/scss/avocadoVariables";
@import "../../../../../assets/scss/mixin";
@import "../../../../../assets/scss/variables";

.repeatActions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: map-get($spacing, xs);
  margin: map-get($spacing, md) 0 0;
}

.readOnlyValues {
  margin: map-get($spacing, md) 0 0 0;
}

.repeat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: map-get($spacing, md) 0 0;

  &_radio {
    width: auto !important;
    padding: 0 0 0 1.5rem;
    &:first-of-type {
      padding: 0;
    }
  }

  &_content {
    .weeklyContainer {
      display: grid;
      grid-template-columns: repeat(2, minmax(210px, 442px));
      gap: map-get($spacing, xl);
      margin: map-get($spacing, md) 0 0;
      flex-wrap: wrap;
      width: 100%;

      &__singleWeekly {
        grid-template-columns: 1fr;
      }

      &_weekly {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: map-get($spacing, sm);
        max-width: 442px;

        .weeklyWeeks {
          display: flex;
          flex-wrap: wrap;

          [class*="_tooltip"] {
            [class*="_weeklyItem"] {
              position: relative;
            }

            &:has(> .weeklyItem__highlight) {
              &::before {
                content: "";
                position: absolute;
                width: calc(100% + 0.5rem);
                height: calc(100% + 0.5rem);
                left: -4px;
                top: -4px;
                background: linear-gradient(
                    0deg,
                    var(--color-primary-30) 0%,
                    var(--color-primary-30) 100%
                  ),
                  var(--color-neutral-00);
              }
            }
          }

          &.weeklyWeeks__dark {
            button[class*="_weeklyItem"]:not([class*="_weeklyItem__disabled"]) {
              &[class*="_weeklyItem__selected"],
              &:active {
                background: linear-gradient(
                    0deg,
                    color-mix(in srgb, var(--color-primary-40) 20%, transparent) 0%,
                    color-mix(in srgb, var(--color-primary-40) 20%, transparent) 100%
                  ),
                  var(--color-primary-20);
                color: var(--color-neutral-70);
              }
            }

            button[class*="_weeklyItem__disabled"] {
              background-color: var(--color-gray-100-00);
              border-color: var(--color-gray-100-15) !important;

              &[class*="_weeklyItem__selected"] {
                background: var(--color-gray-100-15);
              }
            }
          }

          button[class*="_weeklyItem"]:not([class*="_weeklyItem__disabled"]) {
            &[class*="_weeklyItem__selected"],
            &:active {
              background: linear-gradient(
                  0deg,
                  color-mix(in srgb, var(--color-brand-40) 20%, transparent) 0%,
                  color-mix(in srgb, var(--color-brand-40) 20%, transparent) 100%
                ),
                var(--color-primary-20);
              color: var(--color-neutral-70);
              box-shadow: var(--shadow-sm);
            }
          }

          button[class*="_weeklyItem__disabled"] {
            &[class*="_weeklyItem__selected"] {
              border-color: color-mix(in srgb, var(--color-gray-30) 0.05%, transparent);
              background: var(--color-gray-30-30);
            }
          }
        }
      }
    }
  }
}

.weeklyWarning {
  margin: map-get($spacing, md) 0 0;
}
