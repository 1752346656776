@import "../../../../assets/scss/avocadoVariables";

.sectionBox {
  display: flex;
  padding: map-get($spacing, xl) map-get($spacing, md);

  &_icon {
    flex-shrink: 0;
  }

  &_content {
    flex: 1;
    margin: 0 0 0 map-get($spacing, sm);

    .contentTitle {
      display: flex;
      align-items: center;
      color: var(--color-neutral-70);
      margin: 0 0 map-get($spacing, xs);
    }

    .contentDescription {
      color: var(--color-neutral-70);
    }

    .contentDivider {
      border-color: var(--color-neutral-10);
      margin: map-get($spacing, md) 0;
    }

    .contentButtonGroup {
      display: flex;
      gap: map-get($spacing, md);
      margin: map-get($spacing, xl) 0 0;
    }
  }
}
