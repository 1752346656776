@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.recommendationsSectionDetails {
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, sm);

  &.recommendationsSectionDetails__dark {
    .recommendationsSectionDetails_content {
      background-color: var(--color-gray-100-10);
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);
    padding: map-get($spacing, sm);
    border-radius: map-get($radius, lg);
    background-color: var(--color-gray-30-10);

    .contentTitle {
      font-size: map-get($sizeText, 12);
      line-height: map-get($sizeText, 16);
    }

    .packagesList {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
