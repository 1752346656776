@import "../../assets/scss/mixin";
@import "../../assets/scss/avocadoVariables";

.layout {
  background: var(--color-neutral-00);
  min-height: 100vh;

  .header {
    height: 50px;
    position: relative;
    background: var(--color-neutral-00);
    box-shadow: var(--shadow-elevate);
    z-index: 999;

    .btnMenu {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      background: var(--color-neutral-00);
      transition: background 0.3s ease;

      &:hover {
        background: var(--color-neutral-30-20);
      }

      &:active {
        background: linear-gradient(0deg, var(--color-gray-30-30) 0%, var(--color-gray-30-30) 100%),
          var(--color-neutral-05);
      }
    }

    .btnUser {
      align-items: center;
      background: var(--color-neutral-50);
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      height: 32px;
      justify-content: center;
      width: 32px;
    }

    .cntImage {
      @include flex(center, center);
      padding: map-get($spacing, xxs) map-get($spacing, sm);
      border-left: map-get($borders, md) solid var(--color-neutral-30);
      cursor: pointer;

      .img {
        flex-shrink: 0;
        margin: 0;
      }
    }

    &_moduleName {
      position: relative;
      padding: map-get($spacing, sm) 0 map-get($spacing, xs) map-get($spacing, xs);
      color: var(--color-neutral-50);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: auto;

      &::before {
        content: "";
        position: absolute;
        width: map-get($borders, md);
        height: 24px;
        top: map-get($spacing, md);
        left: 0;
        background-color: var(--color-neutral-30);
      }
    }

    .cntInfo {
      @include flex(center, center);
      flex: 1;
      margin: 0 auto 0 map-get($spacing, sm);

      &_autocomplete {
        width: 100%;
        max-width: 400px;
      }
    }

    .containerUser {
      @include flex(center, center);
      background: none;
      cursor: pointer;
      height: 50px;
      width: 50px;
      border-left: map-get($borders, md) solid var(--color-neutral-10);

      &_btn {
        align-items: center;
        background: var(--color-neutral-50);
        border-radius: 25px;
        cursor: pointer;
        display: flex;
        height: 32px;
        justify-content: center;
        width: 32px;
      }

      &.containerUser__active {
        border-left: map-get($borders, md) solid var(--color-neutral-10);
        background: linear-gradient(0deg, var(--color-gray-30-30) 0%, var(--color-gray-30-30) 100%),
          var(--color-neutral-05);
      }
    }
  }
}
