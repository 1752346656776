@import "./../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.menuLanguage {
  .menuLanguage_btn {
    background-color: transparent;
    border-radius: map-get($radius, md);
    border: map-get($borders, md) solid transparent;
    cursor: pointer;
    display: flex;
    padding: map-get($spacing, xs);
    width: 100%;

    .btn_label {
      color: var(--color-neutral-70);
    }

    &:hover {
      border-color: var(--color-primary-30);
      background-color: var(--color-primary-10);
    }

    &.menuLanguage_btn__active {
      background-color: var(--color-primary-10);
      border-color: var(--color-primary-30);
    }
  }

  &_dropdown {
    background: var(--color-neutral-00);
    border-radius: map-get($radius, lg);
    box-shadow: var(--shadow-lg);
    overflow: hidden;
    position: fixed;
    padding: map-get($spacing, md) map-get($spacing, xs);
    right: 333px;
    top: 75px;
    width: 254px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xs);

    .item {
      background-color: transparent;
      border-radius: map-get($radius, md);
      border: map-get($borders, md) solid transparent;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: map-get($spacing, xs);

      .item_label {
        color: var(--color-neutral-70);
      }

      &.item__active {
        background-color: var(--color-primary-10);
        border-color: var(--color-primary-30);
      }

      &:hover {
        border-color: var(--color-primary-30);
        background-color: var(--color-primary-10);
      }
    }
  }
}
