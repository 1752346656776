@import "./../../../../assets/scss/mixin";
@import "../../../../assets/scss/avocadoVariables";

.menuUser {
  background: var(--color-neutral-00);
  border-radius: map-get($radius, lg);
  box-shadow: var(--shadow-lg);
  cursor: default;
  height: 376px;
  overflow: hidden;
  position: absolute;
  right: 9px;
  top: 55px;
  width: 324px;
  z-index: 1000;

  &_options {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, xxs);
    padding: map-get($spacing, sm) map-get($spacing, xs);

    .item {
      padding: map-get($spacing, xs);
      border-radius: map-get($radius, md);
      border: map-get($borders, md) solid transparent;
      cursor: pointer;

      &.item__link {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .item_label {
          padding: map-get($spacing, xs);
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        border-color: var(--color-primary-30);
        background-color: var(--color-primary-10);
      }

      &_label {
        color: var(--color-neutral-70);
        font-size: map-get($sizeText, 14);
      }
    }

    .changeTheme {
      align-items: center;
      display: flex;
      font-size: map-get($sizeText, 14);
      justify-content: space-between;
      padding: map-get($spacing, xs);
      position: relative;

      .changeTheme_label {
        color: var(--color-neutral-70);
      }
    }
  }

  .menuUser_logout {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 324px;
    padding: 0 map-get($spacing, sm) map-get($spacing, sm);

    .divider {
      border-color: var(--color-neutral-10);
      margin: 0 0 map-get($spacing, sm);
    }

    .btn {
      height: 42px;
      padding: map-get($spacing, sm) map-get($spacing, md);
      width: 100%;
    }
  }
}
