@import "../../../../../../assets/scss/mixin";
@import "../../../../../../assets/scss/avocadoVariables";

.recommendationsSection {
  border-radius: map-get($radius, lg);
  border: 1px solid var(--color-primary-50);
  background-color: var(--color-neutral-05);
  box-shadow: var(--shadow-md);

  &.recommendationsSection__dark {
    border-color: var(--color-neutral-30);
    background-color: var(--color-neutral-00);
  }

  &.recommendationsSection__closed {
    border-radius: map-get($spacing, xxl);

    &:not(.recommendationsSection__disabled):hover,
    &:not(.recommendationsSection__disabled):active {
      .recommendationsSection_header {
        background-color: var(--color-primary-50);

        .headerLeft {
          &_title {
            color: var(--color-brand-10);
          }
        }

        svg {
          path {
            fill: var(--color-brand-10);
          }
        }
      }
    }

    .recommendationsSection_header {
      border-radius: map-get($spacing, xxl);
      padding: map-get($spacing, xxs) map-get($spacing, xs) map-get($spacing, xxs)
        map-get($spacing, sm);
      height: 100%;
      max-height: 40px;

      .headerLeft {
        &_title {
          font-size: map-get($sizeText, 12);
          line-height: map-get($sizeText, 14);
          color: var(--color-neutral-70);
        }
      }
    }

    .recommendationsSection_content {
      opacity: 0;
    }
  }

  &.recommendationsSection__disabled {
    border: none;
    box-shadow: var(--shadow-sm);
    background-color: var(--color-neutral-10);

    .recommendationsSection_header {
      background-color: var(--color-neutral-10) !important;
      cursor: not-allowed;

      .headerLeft {
        &_title {
          color: var(--color-neutral-50) !important;
        }
      }

      svg {
        path {
          fill: var(--color-neutral-50) !important;
        }
      }
    }
  }

  &_header {
    border-radius: map-get($radius, lg) map-get($radius, lg) 0 0;
    background-color: var(--color-blocked-opacity);
    transition: background-color 0.25s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: map-get($spacing, sm);
    padding: map-get($spacing, sm) map-get($spacing, xs) map-get($spacing, sm) map-get($spacing, sm);
    cursor: pointer;

    .headerLeft {
      &_icon {
        flex-shrink: 0;
      }

      &_title {
        transition: color 0.25s;
      }
    }

    svg {
      path {
        transition: fill 0.25s;
      }
    }

    &:hover,
    &:active {
      background-color: var(--color-primary-50);

      .headerLeft {
        &_title {
          color: var(--color-brand-10);
        }
      }

      svg {
        path {
          fill: var(--color-brand-10);
        }
      }
    }

    .headerLeft {
      display: flex;
      align-items: center;
      gap: map-get($spacing, xxs);
    }

    .headerToggle {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: map-get($spacing, sm);
    padding: map-get($spacing, sm);
    min-width: 384px;
    transition: opacity 0.25s;

    .recommendationsAlternatives {
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, lg);

      &_title {
        display: flex;
        align-items: center;
        gap: map-get($spacing, xs);

        .titleTooltip {
          display: flex;

          &_content {
            display: flex;
            align-items: center;
            gap: map-get($spacing, xxs);
          }
        }
      }

      &_tabs {
        .alternativeTabs {
          [role="tablist"] {
            overflow: auto;

            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
              border-radius: map-get($radius, lg);
            }

            &::-webkit-scrollbar-thumb {
              background: var(--color-neutral-30);
              border-radius: map-get($radius, lg);
            }

            &::-webkit-scrollbar-corner {
              background: transparent;
            }
          }

          [class*="_tabs_content"] {
            padding-bottom: 0;
          }

          button[role="tab"] {
            flex: 1;
            height: 24px;
            min-width: 52px;
            padding: map-get($spacing, xxs);
            font-size: map-get($sizeText, 12);
            line-height: map-get($sizeText, 16);
          }

          &.alternativeTabs__dark {
            .alternativeTabs_content {
              background-color: var(--color-gray-100-10);
            }
          }

          &_content {
            border-radius: map-get($radius, lg);
            background-color: var(--color-gray-30-10);
            padding: map-get($spacing, sm);
            display: flex;
            flex-direction: column;
            overflow: auto;
            max-height: 300px;

            &::-webkit-scrollbar {
              width: 8px;
              height: 8px;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
              border-radius: map-get($radius, lg);
            }

            &::-webkit-scrollbar-thumb {
              background: var(--color-neutral-30);
              border-radius: map-get($radius, lg);
            }

            &::-webkit-scrollbar-corner {
              background: transparent;
            }

            .contentBox {
              display: flex;
              flex-direction: column;
              gap: map-get($spacing, lg);

              &:not(:last-of-type) {
                margin-bottom: map-get($spacing, sm);
                padding-bottom: map-get($spacing, sm);
                border-bottom: 1px solid var(--color-neutral-30);
              }
            }
          }
        }
      }
    }
  }
}
